import {llget,drugPut,drugPost,drugDel,postUserId,putDragById,delUserId,drugGet,patch,getDrugExel} from "./base";

// 导出药品收藏  
export function getCollectionExport(data){
  return getDrugExel("/v3/drugs/back/collectionExport",data);
}
// 导出纠错记录
export function getdrugErrorExport(data){
  return getDrugExel("/v3/drugs/back/drugErrorExport",data);
}
// 导出分享记录 
export function getshareRecordExport(data){
  return getDrugExel("/v3/drugs/back/shareRecordExport",data);
}
// 导出搜索记录
export function getSearchExcel(data){
  return getDrugExel("/v3/drugs/back/searchExport",data);
}
// 查询
export function getSearchRecord(startTime, endTime, curPage, pageSize) {
  return drugGet(
    `/v3/drugs/searchRecord?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}`
  );
}
// 药品查询分享
export function getDrugShareRecord(startTime, endTime, curPage, pageSize) {
  return drugGet(
    `/v3/drugs/back/drugShareRecord?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}`
  );
}
// 药品查询管理 -- 药品管理
// 按id查询药品
export function getDrugsById(id) {
  return drugGet(`/v3/drugs/getById/${id}`);
}
// 删除
export function delDrugs(id) {
  return drugDel(`/v3/drugs/delete/${id}`);
}
// 修改
export function updateDrugs(data) {
  return drugPut(`/v3/drugs/update`, data);
}
// 开关
export function putUpdate(data) {
  return drugPut(`/v3/drugs/update`, data);
}
// 查询
export function getDragList(isPublish, name, curPage, pageSize) {
  return drugGet(
    `/v3/drugs/back/getList?isPublish=${isPublish}&name=${name}&curPage=${curPage}&pageSize=${pageSize}`
  );
}
// 新增
export function postDrugs(data) {
  return drugPost(`/v3/drugs/insert`, data);
}
// 药品查询管理 -- 收藏记录
// 查询
export function getcollection(startTime, endTime, curPage, pageSize) {
  return drugGet(
    `/v3/drugs/back/collection?startTime=${startTime}&endTime=${endTime}&curPage=${curPage}&pageSize=${pageSize}`
  );
}
//纠错记录
export function getErrorRecord(curPage, pageSize, startTime, endTime, status) {
  return drugGet(
    `/v3/drugs/back/errorRecord?curPage=${curPage}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}&status=${status}`
  );
}
export function editErrorRecord(data) {
  return drugPut("/v3/drugs/back/errorRecord", data);
}

